class BottomSheet {
    constructor({id, config = {}, callbacks = {}}) {
        this.config = config;
        this.onCloseCallback = callbacks?.closeCallback;
        this.onOpenCallback = callbacks?.openCallback;
        this.bottomSheet = document.querySelector(id);
        this.draggableAreaSelector = this.config.draggableAreaSelector || '.draggable-area';
        this.draggableArea = this.bottomSheet?.querySelector(this.draggableAreaSelector);
        this.visibleHeightOnClose = this.config.visibleHeightOnClose || 0;
        this.initialHeight = this.bottomSheet?.offsetHeight;
        this.isOpen = true;
        this.isActive = false;
        this.deltaY = 0;
        this.contentLoaded = false;
    }

    async init() {
        await this.waitForContent();
        this.initialHeight = this.calculateTotalHeight();
        this.bottomSheet.style.maxHeight = `${this.initialHeight}px`;

        // Add event listeners
        this.draggableArea?.addEventListener('mousedown', this.dragStart.bind(this));
        this.bottomSheet?.addEventListener('mousemove', this.drag.bind(this));
        window.addEventListener('mouseup', this.dragEnd.bind(this));

        this.draggableArea?.addEventListener('touchstart', this.dragStart.bind(this));
        this.bottomSheet?.addEventListener('touchmove', this.drag.bind(this));
        window.addEventListener('touchend', this.dragEnd.bind(this));
    }

    close() {
        this.bottomSheet.style.maxHeight = `${this.visibleHeightOnClose}px`;
        this.bottomSheet.classList.add('tft-delivery-box-closed');
        this.isOpen = false;
        if (!window.TFT.options.isPreview) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

        if (this.onCloseCallback) {
            this.onCloseCallback();
        }
    }

    open() {
        this.bottomSheet.style.maxHeight = `${this.initialHeight}px`;
        this.bottomSheet.classList.remove('tft-delivery-box-closed');
        this.isOpen = true;

        if (this.onOpenCallback) {
            this.onOpenCallback();
        }
    }

    toggle() {
        this.isOpen ? this.close() : this.open();
    }

    touchPosition(event) {
        return event.touches ? event.touches[0] : event;
    }

    dragStart(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isActive = true;
        this.dragPosition = this.touchPosition(event).pageY;
        this.deltaY = 0;
    }

    drag(event) {
        if(!this.isActive) return;
        const y = this.touchPosition(event).pageY;
        const deltaY = this.dragPosition - y;
        this.dragPosition = y;
        this.deltaY = deltaY;

        const newHeight = parseInt(this.bottomSheet.style.maxHeight.split("px")[0]) + deltaY;
        if(newHeight > this.initialHeight || newHeight < this.visibleHeightOnClose)
            return

        this.bottomSheet.style.maxHeight = `${newHeight}px`;
    }

    dragEnd() {
        if(!this.isActive) return;
        if(this.deltaY === 0 ){ // Like a click event
            this.toggle()
        }
        else{
            this.deltaY > 0 ? this.open() : this.close();
        }
        this.isActive = false;
    }

    async waitForContent() {
        if (this.contentLoaded) return;

        const images = Array.from(this.bottomSheet.getElementsByTagName('img'));

        const imagePromises = images.map(img => {
            return new Promise((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => resolve(); // Resolve even on error to prevent hanging
                }
            });
        });

        await Promise.all(imagePromises);
        this.contentLoaded = true;
    }

    calculateTotalHeight() {
        this.bottomSheet.style.maxHeight = 'none';
        const height = this.bottomSheet.scrollHeight;
        return height;
    }
}

export default BottomSheet;
